const _temp0 = require("../click-for-life/logo.png");
const _temp1 = require("../click-for-life/youtube_thumbnail.png");

const _temp2 = require("../dual-cat/dual-cat-carousel.png");
const _temp3 = require("../dual-cat/logo.png");
const _temp4 = require("../dual-cat/youtube_thumbnail.png");

const _temp5 = require("../fluffy-out/logo.png");
const _temp6 = require("../fluffy-out/youtube_thumbnail.png");

const _temp7 = require("../misland/logo.png");
const _temp8 = require("../misland/youtube_thumbnail.png");

const _temp9 = require("../rusher-crusher/logo.png");
const _temp10 = require("../rusher-crusher/youtube_thumbnail.png");

module.exports = {
  "click-for-life":   {
    "logo": _temp0,
    "youtube_thumbnail": _temp1
  },
  "dual-cat":   {
    "dual-cat-carousel": _temp2,
    "logo": _temp3,
    "youtube_thumbnail": _temp4
  },
  "fluffy-out":   {
    "logo": _temp5,
    "youtube_thumbnail": _temp6
  },
  "misland":   {
    "logo": _temp7,
    "youtube_thumbnail": _temp8
  },
  "rusher-crusher":   {
    "logo": _temp9,
    "youtube_thumbnail": _temp10
  }
}