const _temp0 = require("url:./alpaca.png");
const _temp1 = require("url:./favicon.png");
const _temp2 = require("url:./google.png");
const _temp3 = require("url:./logo.png");
const _temp4 = require("url:./poki.png");
module.exports = {
  "alpaca": _temp0,
  "favicon": _temp1,
  "google": _temp2,
  "logo": _temp3,
  "poki": _temp4
}