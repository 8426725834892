{
  "fluffy-out": {
    "id": "fluffy-out",
    "name": "Fluffy Out",
    "logo": "./content/fluffy-out/logo.png",
    "header": "./content/fluffy-out/logo.png",
    "description": "In this delightful and visually appealing game, players enter a world where the primary objective is to save adorable, fluffy creatures from various challenging scenarios. Each level is uniquely designed with vibrant graphics and creative obstacles that will captivate your attention and test your problem-solving skills.",
    "links": {
      "poki": "https://poki.com/en/g/fluffy-out",
      "playmarket": "https://play.google.com/store/apps/details?id=com.sealunicorngames.fluffyout",
      "itch": "",
      "youtube": "https://www.youtube.com/watch?v=imasb3ZuK5U"
    }
  },
  "misland": {
    "id": "misland",
    "name": "Misland",
    "logo": "./content/misland/logo.png",
    "header": "./content/misland/logo.png",
    "description": "Adventure crafting and building game with idle elements. Turn deserted island to thriving village!",
    "links": {
      "poki": "https://poki.com/en/g/misland",
      "playmarket": "https://play.google.com/store/apps/details?id=com.sealunicorngames.misland",
      "itch": "",
      "youtube": "https://www.youtube.com/watch?v=uVJRbjktV1Q"
    }
  },
  "dual-cat": {
    "id": "dual-cat",
    "name": "Dual Cat",
    "logo": "./content/dual-cat/thumbnail_dual_cat.png",
    "header": "./content/dual-cat/dual-cat-carousel.png",
    "description": "The puzzle-platformer game where you help Erwin to save Max from the laboratory. Use your mind and agility.",
    "links": {
      "poki": "https://poki.com/en/g/dual-cat",
      "playmarket": "https://play.google.com/store/apps/details?id=com.sealunicorngames.dualcat",
      "itch": "https://seal-unicorn.itch.io/dual-cat-poki",
      "youtube": "https://www.youtube.com/watch?v=3Poy22kfuPs&ab_channel=SealUnicornGames"
    }
  },
  "rusher-crusher": {
    "id": "rusher-crusher",
    "name": "Rusher Crusher",
    "logo": "./content/rusher-crusher/thumbnail_rusher_crusher.png",
    "header": "./content/rusher-crusher/rusher-crusher-carousel.png",
    "description": "The defense you thirty from an awful pixelate monster! Shoot, earn, mine, upgrade!",
    "links": {
      "poki": "https://poki.com/en/g/rusher-crusher",
      "playmarket": "https://play.google.com/store/apps/details?id=com.sealunicorngames.rushercrusher&pli=1",
      "itch": "",
      "youtube": "https://www.youtube.com/watch?v=Su1G-A2Ist4&ab_channel=SealUnicornGames"
    }
  },
  "click-for-life": {
    "id": "click-for-life",
    "name": "Click For Life",
    "logo": "./content/click-for-life/LogoWithTap.png",
    "header": "./content/click-for-life/dual-cat-carousel.png",
    "description": "Welcome to Click For Life - the brand new idle clicker game. In this idle simulation game, you fill the world with different creatures and observe how they interact with the environment.\n Like in the real world every animal needs food. So, beware don't allow them to suffer from starving.\nKey features\nOffline progress\nNo need to be in the game all time. You can close game and take earned genes when you cameback.\nUpgrade\nYour animal can evolve with you help. Improve their stats - make world better\nCreate Life\nBuild your own world where creatures and plants live in balance.\nEarn genes\nMore creature - more genes. More genes - more creature.\nKeep balance\nOne animal eats another one. Ensure that none extinct without food\nClick Click Click\nClick for getting even more genes\n\nEnjoy the new addictive idle life simulator game!",
    "links": {
      "poki": "",
      "playmarket": "https://play.google.com/store/apps/details?id=com.SealUnicorn.ClickForLife",
      "itch": "https://seal-unicorn.itch.io/click-for-living-ludum-dare-49",
      "youtube": ""
    }
  }
}
