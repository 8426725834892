/* in "bs5-lightbox" modules manual change carousel parametr data-bs-ride to "false" (default from original package it's "true" and it causes infinity autoplay) in node 

`\n\t\t\t<div id="lightboxCarousel-${this.hash}" class="${n}" data-bs-ride="false" data-bs-interval="${this.carouselOptions.interval}">\n\t\t\t\t<div class="carousel-inner">\n\t\t\t\t\t${o}\n\t\t\t\t</div>\n\t\t\t\t${i}\n\t\t\t</div>`

in all packages .js files
*/

import "bs5-lightbox";
import "bootstrap";
import games from "./content/games.json";
import gameImages from "./content/*/*.png";
import gameScreenshots from "url:./content/*/screenshots/*.png";
import contentImages from "url:./content/*.png";
import Lightbox from "bs5-lightbox";

console.log(games)
window.addEventListener("load", (event) => {
  goToPage(event);
});

function goToPage(event) {
  // Initial page loading get as tag window.location
  let tag = event.type === "load" ? window.location : event.currentTarget;

  if (tag.href && tag.className != "screenshots") {
    // It's a same-origin navigation: a link within the site.
    if (tag.origin == document.location.origin) {
      let newPath = tag.pathname;
      let gamesPathname = Object.keys(games).map((name) => `/games/${name}`);

      let pathes = [
        ...gamesPathname,
        "/",
        "/homepage",
        "/games",
        "/contacts",
        "/privacy-policy",
        "/about-us",
      ];
      if (pathes.includes(newPath)) {
        event.preventDefault();
        app(newPath);
      } else {
        loadStatic("error404");
      }
      window.history.pushState({}, "", newPath);
      $(".navbar-collapse").collapse("hide");
    }
  }
}

window.onpopstate = function (event) {
  if (event.state) {
    app(window.location.pathname);
  }
};

function app(path) {
  // https://sealunicorn.com/
  // By default, load the homepage
  if (path === undefined) {
    loadHomepage();
  } else {
    if (path.startsWith("/games/")) {
      loadGame(path.slice(7));
    }
    switch (path) {
      case "/homepage":
      case "/":
        loadHomepage();
        break;
      case "/games":
        loadGames();
        break;
      case "/contacts":
        loadStatic("contacts");
        break;
      case "/privacy-policy":
        loadStatic("privacy-policy");
        break;
      case "/about-us":
        loadStatic("about-us");
        break;
    }
  }

  const element = document.getElementById("header-1-row");
  element.scrollIntoView();

  document.querySelectorAll("a").forEach((link) =>
    link.addEventListener("click", (e) => {
      // console.log(e.currentTarget);
      goToPage(e);
    })
  );
}

function showView(view) {
  document.querySelector("#homepage").style.display = "none";
  document.querySelector("#single-game-view").style.display = "none";
  document.querySelector("#contacts").style.display = "none";
  document.querySelector("#privacy-policy").style.display = "none";
  document.querySelector("#error").style.display = "none";
  document.querySelector("#about-us").style.display = "none";
  document.querySelector(view).style.display = "block";
}

function loadHomepage() {
  //Clear homepage view before loading
  document.querySelector(
    "#homepage"
  ).innerHTML = `<div id="homepage-games-title" class="homepage-title">
  <h1>Games by Seal Unicorn Games</h1>
  </div>
  <div id="games"></div>`;
  document.title = "Seal Unicorn";

  // Show the homepage and hide other views
  showView("#homepage");

  let gameList = Object.keys(games);
  // ['dual-cat', 'rusher-crusher', 'click-for-life']

  for (let i = 0; i < gameList.length; i++) {
    let gameObj = games[gameList[i]];
    let gameBlock = document.createElement("div");
    gameBlock.className = "game-block";
    gameBlock.id = gameObj.id;

    let gameAnchor = document.createElement("a");
    gameAnchor.href = `/games/${gameObj.id}`;

    let gameImage = document.createElement("img");
    gameImage.src = gameImages[gameList[i]].logo;
    gameImage.alt = `${gameObj.id} logo-link`;
    gameImage.className = "image";

    let gameLabel = document.createElement("div");
    gameLabel.className = "middle";

    let gameLabelText = document.createElement("div");
    gameLabelText.className = "text";
    gameLabelText.innerText = `${gameObj.name}`;

    // let gameLabelButton = document.createElement("button");
    // gameLabelButton.type = "button";
    // gameLabelButton.className = "btn btn-outline-light";
    // gameLabelButton.innerText = "More Info";

    gameLabel.appendChild(gameLabelText);
    // gameLabel.appendChild(gameLabelButton);
    gameAnchor.appendChild(gameImage);
    gameAnchor.appendChild(gameLabel);
    gameBlock.appendChild(gameAnchor);
    document.querySelector("#games").append(gameBlock);
  }
}

function loadGames() {
  if (document.location.pathname !== "/homepage") {
    loadHomepage();
  }
  document.title = "Games | Seal Unicorn";
  const element = document.getElementById("homepage");
  element.scrollIntoView();
}

// Function load contacts or privacy policy view
function loadStatic(view) {
  switch (view) {
    case "privacy-policy":
      document.title = "Privacy Policy | Seal Unicorn";
      showView("#privacy-policy");
      break;
    case "contacts":
      document.title = "Privacy Policy | Seal Unicorn";
      showView("#contacts");
      break;
    case "about-us":
      document.title = "About us | Seal Unicorn";
      showView("#about-us");
      break;
    case "error404":
      document.title = "Oops! 404 | Seal Unicorn";
      showView("#error");
      break;
  }
}

function loadGame(gameId) {
  //Clear game view page before loading
  document.querySelector("#game-view").innerHTML = "";
  // Show single game view and hide other views
  showView("#single-game-view");

  //Put game information object to gameInfo
  let gameInfo = games[gameId];
  document.title = `${gameInfo.name} | Seal Unicorn`;

  //Create title element
  let title = document.createElement("div");
  title.id = "game-title";
  title.innerText = `${gameInfo.name}`;
  //Create description element
  let description = document.createElement("div");
  description.id = "game-description";
  description.innerText = `${gameInfo.description}`;
  //Create element with links on poki or playmarket
  let links = document.createElement("div");
  links.id = "game-links";
  let linksFigure = document.createElement("figure");
  for (let i = 0, arr = ["poki", "playmarket"]; i < arr.length; i++) {
    if (!gameInfo.links[arr[i]]) {
      continue;
    }
    let linkAnchor = document.createElement("a");
    linkAnchor.href = gameInfo.links[arr[i]];
    linkAnchor.target = "_blank";
    linkAnchor.rel = "nofollow noopener";
    linkAnchor.referrerpolicy = "origin";

    let linkImage = document.createElement("img");
    if (i === 0) {
      linkImage.height = "50";
      linkImage.src = contentImages.poki;
      linkImage.alt = "Play on Poki";
      linkImage.title = "Play on Poki";
    } else if (i === 1) {
      linkImage.height = "50";
      linkImage.src = contentImages.google;
      linkImage.alt = "Get it on Google Play";
      linkImage.title = "Get it on Google Play";
      linkImage.loading = "lazy";
    }

    linkAnchor.appendChild(linkImage);
    linksFigure.appendChild(linkAnchor);
  }
  links.appendChild(linksFigure);

  //Create element with screenshots
  let screenshots = document.createElement("div");
  screenshots.id = "game-screenshots";


  if (gameInfo.links["youtube"]) {
    let youtubeAnchor = document.createElement("a");
    youtubeAnchor.href = gameInfo.links["youtube"];
    youtubeAnchor.className = "screenshots";
    youtubeAnchor.setAttribute("data-gallery", "gallery");
    youtubeAnchor.setAttribute("data-interval", false);

    let youtubeImage = document.createElement("img");
    youtubeImage.className = "img-responsive";

    youtubeImage.src = gameImages[gameId]["youtube_thumbnail"];

    youtubeAnchor.appendChild(youtubeImage);
    screenshots.appendChild(youtubeAnchor);
  }

  for (let shot in gameScreenshots[gameId]) {
    let screenshotsAnchor = document.createElement("a");
    screenshotsAnchor.href = `${gameScreenshots[gameId][shot]}`;
    screenshotsAnchor.className = "screenshots";
    screenshotsAnchor.setAttribute("data-gallery", "gallery");
    screenshotsAnchor.setAttribute("data-interval", false);

    let screenshotsImage = document.createElement("img");
    screenshotsImage.className = "img-responsive";
    screenshotsImage.src = `${gameScreenshots[gameId][shot]}`;

    screenshotsAnchor.appendChild(screenshotsImage);
    screenshots.appendChild(screenshotsAnchor);
  }

  document
    .querySelector("#game-view")
    .append(title, description, links, screenshots);
    
  document
    .querySelectorAll(".screenshots")
    .forEach((el) => el.addEventListener("click", Lightbox.initialize));
}
