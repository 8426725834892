const _temp0 = require("url:../../click-for-life/screenshots/Screenshot.png");

const _temp1 = require("url:../../dual-cat/screenshots/e_SS1.png");
const _temp2 = require("url:../../dual-cat/screenshots/e_SS3.png");
const _temp3 = require("url:../../dual-cat/screenshots/e_SS7.png");

const _temp4 = require("url:../../fluffy-out/screenshots/1.png");
const _temp5 = require("url:../../fluffy-out/screenshots/2.png");
const _temp6 = require("url:../../fluffy-out/screenshots/3.png");
const _temp7 = require("url:../../fluffy-out/screenshots/4.png");
const _temp8 = require("url:../../fluffy-out/screenshots/5.png");
const _temp9 = require("url:../../fluffy-out/screenshots/6.png");

const _temp10 = require("url:../../misland/screenshots/tab1.png");
const _temp11 = require("url:../../misland/screenshots/tab2.png");
const _temp12 = require("url:../../misland/screenshots/tab3.png");
const _temp13 = require("url:../../misland/screenshots/tab4.png");
const _temp14 = require("url:../../misland/screenshots/tab5.png");
const _temp15 = require("url:../../misland/screenshots/tab6.png");
const _temp16 = require("url:../../misland/screenshots/tab7.png");

const _temp17 = require("url:../../rusher-crusher/screenshots/SS_donut.png");
const _temp18 = require("url:../../rusher-crusher/screenshots/SS_menu.png");
const _temp19 = require("url:../../rusher-crusher/screenshots/SS_tomato.png");

module.exports = {
  "click-for-life":   {
    "Screenshot": _temp0
  },
  "dual-cat":   {
    "e_SS1": _temp1,
    "e_SS3": _temp2,
    "e_SS7": _temp3
  },
  "fluffy-out":   {
    "1": _temp4,
    "2": _temp5,
    "3": _temp6,
    "4": _temp7,
    "5": _temp8,
    "6": _temp9
  },
  "misland":   {
    "tab1": _temp10,
    "tab2": _temp11,
    "tab3": _temp12,
    "tab4": _temp13,
    "tab5": _temp14,
    "tab6": _temp15,
    "tab7": _temp16
  },
  "rusher-crusher":   {
    "SS_donut": _temp17,
    "SS_menu": _temp18,
    "SS_tomato": _temp19
  }
}